import React from "react";
import FormCard from "./FormCard/FormCard";
import "./Landing.scss";
import { Link } from "react-router-dom";

export default function Landing() {
  return (
    <>
      <div className="landing_container" id="my-section">
        <div className="landing_first">
          <h1 className="head">
            Elevate Your Brand <div> WITH IMPACTFUL SOCIAL MEDIA ADS</div>
          </h1>

          <div className="landing_para">
            <p>
              We transform concepts and unedited footage into visually stunning
              videos
            </p>
            <Link to="submitchoices">
              {" "}
              <a>
                <button className="see-btn">See pricing</button>
              </a>{" "}
            </Link>
            <Link to="calendly">
              {" "}
              <a>
                <button className="see-btn c-btn">Book a call</button>
              </a>{" "}
            </Link>
          </div>
        </div>
        <div className="landing_form">
          <FormCard />
        </div>
      </div>
    </>
  );
}
