import React from "react";
import QuestionCard from "./QuestionCard/QuestionCard";
import "./FrequentQuestions.scss";

const FrequentQuestions = ({ bgBlur, widthParameter, marginParameter }) => {
  return (
    <div className={"frequent_questions " + marginParameter}>
      <h3>FAQs</h3>
      <div className="questions_container">
        <QuestionCard
          widthParameter={widthParameter}
          question="What type of videos do you edit?"
          answer="Our video editing services encompass various types, from corporate and branded videos to social media. For enquiries, email ' team.adways@gmail.com ' or use live chat."
        />
        <QuestionCard
          widthParameter={widthParameter}
          question="What is the turnaround time for my video?"
          answer={[
            "Standard turnaround 5-7 days, expedite 48 hours available.",
          ]}
        />
        <QuestionCard
          widthParameter={widthParameter}
          question="Can you edit 4K videos?
                    "
          answer={["Yes, We can edit 4K,60fps editing"]}
        />
        <QuestionCard
          widthParameter={widthParameter}
          question="Can you provide multiple exports from the same main video?"
          answer="Yes, We can export multiple videos from one main video for various purposes."
        />
        <QuestionCard
          widthParameter={widthParameter}
          question="What video editing software do you use for editing?"
          answer="Davinci Resolve, Premiere Pro, After Effect & Photoshop"
        />
        <QuestionCard
          widthParameter={widthParameter}
          question="Can you do revisions?


                    "
          answer="Yes, We offer Three free rounds of revisions after the first cut, often achieving the desired edit after one iteration.

                    "
        />

        <QuestionCard
          widthParameter={widthParameter}
          question="How much does it cost to edit my video?
                    "
          answer="Explore our pricing page for cost estimates. However, certain projects may require individual quoting. Reach out to us, and we'll provide a custom offer.

                    "
        />

        <QuestionCard
          widthParameter={widthParameter}
          question="What techniques do your video editing services include?
                    "
          isList={true}
          answer={[
            "Professional Editing",
            "Color Correction & Color Grading ",
            "Motion Graphics",
            "Sound Design & Mixing",
          ]}
        />

        <QuestionCard
          widthParameter={widthParameter}
          question="Can you match a video editing style?

                    "
          answer="Sure, we can follow your preferred video editing style and align it with your brand if you have a branding package.

                    "
        />

        <QuestionCard
          widthParameter={widthParameter}
          question="Can I see examples of your work?

                    "
          answer="Explore our portfolio page for a comprehensive view of our work. For more samples, email us.

                    "
        />
      </div>
      {bgBlur ? null : (
        <img src="/images/FAQs/circle.svg" className="blur-bg" alt=""></img>
      )}
    </div>
  );
};

export default FrequentQuestions;
