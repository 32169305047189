import React from "react";
import "./LearnAndPractice.scss";
import LearnDownloadCard from "./LearnDownloadCard/LearnDownloadCard";

export default function LearnAndPractice() {
  return (
    <div className="learn-and-practice">
      <h1 className="learn-head">How the Process Works</h1>
      <div className="learn-cards-wrapper">
        <LearnDownloadCard
          cardHead="Submit Your Video Files"
          img="/images/unnamed.png"
          para="Share your raw video clips using Google Drive, Dropbox, or a similar cloud storage service. Provide a brief with your video editing instructions, or leave it to our expertise.
                    "
        />
        <LearnDownloadCard
          cardHead="Receive Your Edited Video
                    "
          img="/images/unnamed (1).png"
          para="Expect an email containing a download link for your edited video within an average timeframe of 5-7 days. Opt for the expedited option, and you'll have your edited video in just 48 hours.

                    "
        />

        <LearnDownloadCard
          cardHead=" Request Changes, if Necessary"
          img="/images/unnamed (2).png"
          para="Share your feedback on the video, and we're delighted to collaborate with you on up to 2 free revisions. No additional charges, no strings attached. Your satisfaction is our priority.
                    "
        />
      </div>
      <p id="portfolio"></p>
    </div>
  );
}
