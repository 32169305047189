import React from "react";
import "./More.scss";
import MoreCard from "./MoreCard/MoreCard";
export default function More() {
  return (
    <div className="more_container">
      <h1>Our video editing services encompass</h1>
      <div className="morecard">
        {" "}
        <MoreCard
          MoreImage="/images/WhatsApp Image 2024-02-16 at 12.14.49.jpeg"
          Morepara="Assigned video editor and account manager
            "
        />

        <MoreCard 
         MoreImage="/images/WhatsApp Image 2024-02-16 at 12.14.49.jpeg"
          Morepara="Real-time communication via various channels (Slack, WhatsApp, etc.)
            "
        />
        
        <MoreCard 
         MoreImage="/images/WhatsApp Image 2024-02-16 at 12.14.49.jpeg"
          Morepara="Curating raw footage
            "
        />
        <MoreCard
         MoreImage="/images/WhatsApp Image 2024-02-16 at 12.14.49.jpeg"
          Morepara="Implementing intricate cuts and transitions
            "
        />
        <MoreCard
          MoreImage="/images/WhatsApp Image 2024-02-16 at 12.14.49.jpeg"
          Morepara="Integrating motion graphics and typography

            "
        />
        <MoreCard
          MoreImage="/images/WhatsApp Image 2024-02-16 at 12.14.49.jpeg"
          Morepara="Incorporating licensed stock footage and b-roll

            "
        />
        <MoreCard
          MoreImage="/images/WhatsApp Image 2024-02-16 at 12.14.49.jpeg"
          Morepara="Using licensed soundtracks and sound effects

            "
        />
        <MoreCard
          MoreImage="/images/WhatsApp Image 2024-02-16 at 12.14.49.jpeg"
          Morepara="Enhancing audio through mixing

            "
        />
        <MoreCard
          MoreImage="/images/WhatsApp Image 2024-02-16 at 12.14.49.jpeg"
          Morepara="Conducting multi-camera editing

            "
        />
        <MoreCard
          MoreImage="/images/WhatsApp Image 2024-02-16 at 12.14.49.jpeg"
          Morepara="Applying color correction and grading

            "
        />
        <MoreCard
          MoreImage="/images/WhatsApp Image 2024-02-16 at 12.14.49.jpeg"
          Morepara="Adding subtitles and on-screen text pop-ups

            "
        />
        <MoreCard
          MoreImage="/images/WhatsApp Image 2024-02-16 at 12.14.49.jpeg"
          Morepara="Providing Dropbox space for seamless file transfer

            "
        />
        <MoreCard
         MoreImage="/images/WhatsApp Image 2024-02-16 at 12.14.49.jpeg"
          Morepara="Offering unlimited raw footage duration


            "
        />
        <MoreCard
          MoreImage="/images/WhatsApp Image 2024-02-16 at 12.14.49.jpeg"
          Morepara="Ensuring a turnaround time of 5 working days or expedited delivery within 48 hours


            "
        />
        <MoreCard
          MoreImage="/images/WhatsApp Image 2024-02-16 at 12.14.49.jpeg"
          Morepara="Allowing for 3 free revisions


            "
        />
        <MoreCard
          MoreImage="/images/WhatsApp Image 2024-02-16 at 12.14.49.jpeg"
          Morepara="Supporting video export up to 4K and 60 FPS


            "
        />
        <MoreCard
          MoreImage="/images/WhatsApp Image 2024-02-16 at 12.14.49.jpeg"
          Morepara="Adapting videos to horizontal, vertical, and square aspects



            "
        />
        <MoreCard
          MoreImage="/images/WhatsApp Image 2024-02-16 at 12.14.49.jpeg"
          Morepara="Creating thumbnails and headlines



            "
        />
        <MoreCard
          MoreImage="/images/WhatsApp Image 2024-02-16 at 12.14.49.jpeg"
          Morepara="Repurposing video content into clips tailored for social media



            "
        />
      </div>
      <div id="process"></div>
    </div>
  );
}
