import React from "react";
import "./ResultSection.scss";

export default function ResultSection() {
  return (
    <div className="result-section" id="results">
      <h2 className="result-heading">Case Studies</h2>
      <div className="result-card-slider">
        <div className="result-card-wrapper">
          <div className="img">
            <img src="/images/case1.png"></img>
          </div>
          <div className="img">
            {" "}
            <img src="/images/case2.png"></img>
          </div>
        </div>
      </div>
    </div>
  );
}
