import { createContext, useState } from "react";

export const EssentialContext = createContext();

export const EssentialProvider = ({children}) =>{
    const [essentials, setEssentials] = useState({
        videoPlaying:false
    })
    return(
        <EssentialContext.Provider value={[essentials,setEssentials]}>
            {children}
        </EssentialContext.Provider>
    )
}