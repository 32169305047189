import React from "react";
import "./TermsandCondition.scss";

export default function TermsConditions() {
  return (
    <div className="main-div">
      <h4>Terms and Conditions</h4>
      <p className="head">Welcome to Adways.in!</p>
      <br />
      <p>
        These terms and conditions outline the rules and regulations for the use
        of Adways.in’s Website, located at{" "}
        <a href="https://www.adways.in/">www.adways.in</a>.
      </p>
      <br />
      <p>
        By accessing this website, we assume you accept these terms and
        conditions. Do not continue to use Adways.in if you do not agree to take
        all of the terms and conditions stated on this page.
      </p>
      <br />
      <p>
        The following terminology applies to these Terms and Conditions, Privacy
        Statement, and Disclaimer Notice and all Agreements: “Client,” “You,”
        and “Your” refers to you, the person who logs on this website and
        compliant to the Company’s terms and conditions. “The Company,”
        “Ourselves,” “We,” “Our,” and “Us,” refers to our Company. “Party,”
        “Parties,” or “Us,” refers to both the Client and ourselves. All terms
        refer to the offer, acceptance, and consideration of payment necessary
        to undertake the process of our assistance to the Client in the most
        appropriate manner for the express purpose of meeting the Client’s needs
        in respect of the provision of the Company’s stated services, in
        accordance with and subject to, prevailing law of Netherlands. Any use
        of the above terminology or other words in the singular, plural,
        capitalization, and/or he/she or they, are taken as interchangeable and
        therefore as referring to the same.
      </p>
      <br />
      <p className="head">Cookies</p>
      <br />
      <p>
        We employ the use of cookies. By accessing Adways.in, you agree to use
        cookies in agreement with Adways.in’s Privacy Policy.
      </p>
      <br />
      <p>
        Most interactive websites use cookies to let us retrieve the user’s
        details for each visit. Cookies are used by our website to enable the
        functionality of certain areas to make it easier for people visiting our
        website. Some of our affiliate/advertising partners may also use
        cookies.
      </p>
      <br />
      <p className="head">License</p>
      <br />
      <p>
        Unless otherwise stated, Adways.in and/or its licensors own the
        intellectual property rights for all material on Adways.in. All
        intellectual property rights are reserved. You may access this from
        Adways.in for your own personal use subjected to restrictions set in
        these terms and conditions.
      </p>
      <br />
      <p className="head">You must not:</p>
      <br />
      <ul className="list">
        <li>Republish material from Adways.in</li>
        <li>Sell, rent or sub-license material from Adways.in</li>
        <li>Reproduce, duplicate or copy material from Adways.in</li>
        <li>Redistribute content from Adways.in</li>
      </ul>
      <br />
      <p>This Agreement shall begin on the date hereof.</p>
      <br />
      <p>
        Parts of this website offer an opportunity for users to post and
        exchange opinions and information in certain areas of the website.
        Adways.in does not filter, edit, publish, or review Comments prior to
        their presence on the website. Comments do not reflect the views and
        opinions of Adways.in, its agents, and/or affiliates. Comments reflect
        the views and opinions of the person who posts their views and opinions.
        To the extent permitted by applicable laws, Adways.in shall not be
        liable for the Comments or for any liability, damages, or expenses
        caused and/or suffered as a result of any use of and/or posting of
        and/or appearance of the Comments on this website.
      </p>
      <br />
      <p>
        Adways.in reserves the right to monitor all Comments and to remove any
        Comments that can be considered inappropriate, offensive, or causes a
        breach of these Terms and Conditions.
      </p>
      <br />
      <p className="head">You warrant and represent that:</p>
      <br />
      <ul className="list">
        <li>
          You are entitled to post the Comments on our website and have all
          necessary licenses and consents to do so;
        </li>
        <li>
          The Comments do not invade any intellectual property right, including
          without limitation copyright, patent, or trademark of any third party;
        </li>
        <li>
          The Comments do not contain any defamatory, libelous, offensive,
          indecent, or otherwise unlawful material that is an invasion of
          privacy;
        </li>
        <li>
          The Comments will not be used to solicit or promote business or custom
          or present commercial activities or unlawful activity.
        </li>
      </ul>
      <br />
      <p>
        You hereby grant Adways.in a non-exclusive license to use, reproduce,
        edit, and authorize others to use, reproduce and edit any of your
        Comments in any and all forms, formats, or media.
      </p>
      <br />
      <p className="head">Video Editing Services</p>
      <br />
      <p>
        Adways.in provides video editing services aiming to make the best out of
        the Content the Clients supply.
      </p>
      <p>
        By employing our services, you are agreeing to these Terms. These are
        legal and binding.
      </p>
      <br />
      <p className="head">Definitions</p>
      <br />
      <ul className="list">
        <li>
          The Service: Adways.in (“Service”) is a video editing platform that
          includes video planning, editing, and animation.
        </li>
        <li>
          The Website: The website or{" "}
          <a href="https://www.adways.in/">www.adways.in</a> www.adways.in will
          contain the functionalities you need to use our service.
        </li>
        <li>
        We or Us: The service and website are owned, ran, and managed by Adways.in

        </li>
        <li>
          You: You or “Client” is a customer of the Service or represents an
          individual or group considered to be a customer of the Service.
        </li>
        <li>
          Content: Through the Service, the Client is required to provide video
          footage, other materials, and content (these particulars are referred
          to collectively as “Content”) to Adways.in.
        </li>
      </ul>
      <br />
      <p className="head">Intellectual Property Rights</p>
      <br />
      <p>
        This refers to all current patent rights, mask work rights, rights of
        publicity, copyright rights, moral rights, trade dress, and service mark
        rights, trademark, trade secret rights, goodwill, and other intellectual
        property, as well as those that are to exist in the future
      </p>
    </div>
  );
}
