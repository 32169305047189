import React from "react";
import Navbar from "./Navbar/Navbar";
import Landing from "./LandingPage/Landing";
import More from "./More/More";
import LearnAndPractice from "./Learn and practice/LearnAndPractice";
import VideoSection from "./VideoSection/VideoSection";
import ResultSection from "./ResultSection/ResultSection";
import EnquireSection from "./Enquire section/EnquireSection";
import Footer from "./footer/Footer";
import Preperation from "./Preparation/Preparation";

export default function MainRouter() {
  return (
    <div>
      <Navbar />
      <Landing />
      <Preperation />
      <More />
      <LearnAndPractice />
      <VideoSection />
      <ResultSection />
      <EnquireSection />
      <Footer />
    </div>
  );
}
