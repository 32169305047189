import React, { useEffect, useState } from "react";
import "./PageTwo.scss";
import { Switch } from "pretty-checkbox-react";
import "@djthoms/pretty-checkbox";

export default function PageTwo() {
  const [checkboxValues, setCheckboxValues] = useState({
    addphoto: false,
    addtext: false,
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setCheckboxValues((prevState) => ({
      ...prevState,
      [name]: checked,
    }));

    sessionStorage.setItem(name, checked ? "true" : "false");
  };

  useEffect(() => {
    const savedCheckbox1Value = sessionStorage.getItem("addphoto") === "true";
    const savedCheckbox2Value = sessionStorage.getItem("addtext") === "true";

    setCheckboxValues({
      addphoto: savedCheckbox1Value,
      addtext: savedCheckbox2Value,
    });
  }, []);

  return (
    <div id="pagetwo" className="page-two">
      <form>
        <h1>Anything extra ?</h1>
        <p>
          Do you really need anything else besides just cutting the footage and
          adding transitions ?
        </p>
        <div className="check-box-wrap">
          <Switch
          color="danger"
            type="checkbox"
            checked={checkboxValues.addphoto}
            name="addphoto"
            onChange={handleCheckboxChange}
          >
            I want to add photos
          </Switch>
          <Switch
           color="danger"
            type="checkbox"
            checked={checkboxValues.addtext}
            name="addtext"
            onChange={handleCheckboxChange}
          >
            I want to add animated text and titles
          </Switch>
        </div>
      </form>
    </div>
  );
}
