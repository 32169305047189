import * as React from "react";
import "./PageWrapper.scss";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PageOne from "../Page1/PageOne";
import PageTwo from "../Page2/PageTwo";
import PageThree from "../Page3/PageThree";
import PageFour from "../Page4/PageFour";
import PageFive from "../Page5/PageFive";
import Footer from "../../footer/Footer";
import NavbarTwo from "../../Navbar/NavbarTwo";
import { Link } from "react-router-dom";
import {
  Stepper,
  Step,
  StepLabel,
  StepButton,
  makeStyles
} from "@material-ui/core";


const steps = ["", "", "", "", ""];

export default function SubmitChoices() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSubmit = () => {
    const name = sessionStorage.getItem("name");
    const email = sessionStorage.getItem("email");
    const description = sessionStorage.getItem("description");
    const selectedRawFootage = sessionStorage.getItem("selectedRawFootage");
    const selectedEditedVideo = sessionStorage.getItem("selectedEditedVideo");
    const addphoto = sessionStorage.getItem("addphoto") === "true";
    const addtext = sessionStorage.getItem("addtext") === "true";
console.log("name: " + name, "email: " + email, "description: "+ description,"selectedRawFootage: "  +selectedRawFootage, "selectedEditedVideo: "+selectedEditedVideo, "addphoto: "+addphoto, "addtext: "+addtext)

    const data = { name, email, description, selectedRawFootage, selectedEditedVideo, addphoto, addtext };
    fetch('', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      console.log('success', data);
    })
    .catch(error => {
      console.error('error', error);
    });

    

    sessionStorage.clear();
alert("Data submitted")
   
    setTimeout(() => {
      handleReset();
    }, 3000);
  };
  const useStyles = makeStyles(() => ({
    root: {
      "& .MuiStepIcon-active": { color: "#dc0840" },
      "& .MuiStepIcon-completed": { color: "#dc0840" },
      "& .Mui-disabled .MuiStepIcon-root": { color: "#dc0840" }
    }
  }));
  const c = useStyles();

  const required ={
    value : true,
    message : "the field is required"
  }

  return (
    <>
      <NavbarTwo />
      <div className="submit-div">
        <Stepper className={c.root} id="stepper" activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption"></Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <p className="completed">
            You've completed all the steps. Ready to finalize your choices?
            </p>
            <div sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box />
              <Link  to="/"><button className="button" onClick={handleSubmit}>
                SUBMIT✔
              </button></Link>
              
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {activeStep === 0 && <PageOne />}
            {activeStep === 1 && <PageTwo />}
            {activeStep === 2 && <PageThree/>}
            {activeStep === 3 && <PageFour />}
            {activeStep === 4 && <PageFive />}
            {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
            {}
            <div className="btn-wrap">
              {" "}
              <button
                className="button"
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                Back
              </button>
              <button  className="button" type="submit" onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
      <Footer />
    </>
  );
}
