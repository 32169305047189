import React, { useEffect, useState } from "react";
import "./PageFive.scss";

export default function PageFive() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const win = window.sessionStorage;
  const handleSubmit = (e) => {
    e.preventDefault();
    win.clear();
    setName("");
    setEmail("");
  };

  useEffect(() => {
    if (win.getItem("name")) setName(win.getItem("name"));
    if (win.getItem("email")) setEmail(win.getItem("email"));
  }, []);

  useEffect(() => {
    win.setItem("name", name);
    win.setItem("email", email);
  }, [name, email]);

  return (
    <div className="page-five bg-black">
      <>
        <h1>Put in your name and email</h1>
        <form
          onSubmit={handleSubmit}
          style={{ marginTop: "40px", fontSize: "14px" }}
        >
          <div>
            <label htmlFor="name">Your name</label>
          </div>
          <input
            id="name"
            name="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <div style={{ marginTop: "20px", fontSize: "14px" }}>
            <label htmlFor="email">Your email</label>
          </div>
          <input
            id="email"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <div>
            {/* <button  style={{ padding: "5px 30px" }} type="submit">
            SEND
          </button> */}
          </div>
        </form>
      </>
    </div>
  );
}
