import React, { useContext } from 'react'
import { EssentialContext } from '../../../Assets/EssentialContext'
import './VideoCard.scss'

export default function VideoCard({videoId, thumbnail, video}) {
    const [essentials, setEssentails] = useContext(EssentialContext)

    const handleVideo = (event) =>{
        const hidedPlay = document.querySelector('.hide-play')
        const activeVideo = document.querySelector('.video-active')
        setEssentails({...essentials,videoPlaying:true})
        if(activeVideo){
            activeVideo.classList.remove('video-active')
            hidedPlay.classList.remove('hide-play')
            activeVideo.pause()
        }
        event.target.classList.add("hide-play")
        const video = document.getElementById(videoId)
        video.classList.add('video-active')
        video.play()
    }
    return (
        <div className="video-card" style={{backgroundImage: `url(${thumbnail})`}}>
            <img src="/images/Pasted image 2.png" alt="" onClick={handleVideo}/>
            <video width="100%" height="100%" controls className="video" id={videoId}>
                <source src={video} type="video/mp4"/>
            </video>
        </div>
    )
}
