import React, { useState, useEffect } from "react";
import "./PageOne.scss";

export default function PageOne() {
  const [selectedRawFootage, setSelectedRawFootage] =
    useState("'select an option'");
  const [selectedEditedVideo, setSelectedEditedVideo] = useState("");

  useEffect(() => {
    const savedRawFootageValue = sessionStorage.getItem("selectedRawFootage");
    const savedEditedVideoValue = sessionStorage.getItem("selectedEditedVideo");
    if (savedRawFootageValue) {
      setSelectedRawFootage(savedRawFootageValue);
    }
    if (savedEditedVideoValue) {
      setSelectedEditedVideo(savedEditedVideoValue);
    }
  }, []);

  const handleRawFootageChange = (event) => {
    setSelectedRawFootage(event.target.value);
    sessionStorage.setItem("selectedRawFootage", event.target.value);
  };

  const handleEditedVideoChange = (event) => {
    setSelectedEditedVideo(event.target.value);
    sessionStorage.setItem("selectedEditedVideo", event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSelectedRawFootage("");
    setSelectedEditedVideo("");
    sessionStorage.removeItem("selectedRawFootage");
    sessionStorage.removeItem("selectedEditedVideo");
  };

  return (
    <>
      <div className="page-one">
        <form onSubmit={handleSubmit}>
          <h1>How much footage do you plan on sending us ?</h1>
          <div className="select-wrap">
            <div>
              <div className="select">
                <p>Raw footage duration</p>
                <select
                  value={selectedRawFootage}
                  onChange={handleRawFootageChange}
                  name="Raw footage duration"
                  id=""
                >
                  <option value="">select an option</option>
                  <option value="1 - 30 minutes">1 - 30 minutes</option>
                  <option value="31 - 60 minutes">31 - 60 minutes</option>
                  <option value="61 - 90 minutes">61 - 90 minutes</option>
                  <option value="91 - 120 minutes">91 - 120 minutes</option>
                  <option value="121 - 180 minutes">121 - 180 minutes</option>
                  <option value="181 - 240 minutes">181 - 240 minutes</option>
                </select>
              </div>
            </div>
            <div>
              <div className="select">
                <p>Duration of the edited video</p>
                <select
                  value={selectedEditedVideo}
                  onChange={handleEditedVideoChange}
                  name="Duration of the edited video"
                  id=""
                >
                  <option value="">select an option</option>
                  <option value="30 - 90 seconds">30 - 90 seconds</option>
                  <option value="1 - 3 minutes">1 - 3 minutes</option>
                  <option value="3 - 5 minutes">3 - 5 minutes</option>
                  <option value="5 - 10 minutes">5 - 10 minutes</option>
                  <option value="10 - 20 minutes">10 - 20 minutes</option>
                  <option value="20+ minutes">20+ minutes</option>
                </select>
              </div>
            </div>
          </div>
          <div className="para">
            <p className="p">You plan on sending around {selectedRawFootage}</p>

            <p>
              If you know the exact duration of the raw footage please select a
              value that seems about right to you. we wil adjust the pricing
              after we recieve the footage and lock at the duration.
            </p>
          </div>
        </form>
      </div>
    </>
  );
}
