import React, { useContext, useEffect, useState } from "react";
import { EssentialContext } from "../../Assets/EssentialContext";
import VideoCard from "./VideoCard/VideoCard";
import "./VideoSection.scss";

export default function VideoSection() {
  const [dotActiveness, setDotActiveness] = useState("first-dot-active");
  const [transition, setTransition] = useState("");
  const [swipeStart, setSwipeStart] = useState(0);
  const [essentials, setEssentials] = useContext(EssentialContext);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTrigger(!trigger);
    }, 3000);

    trigger && slideVideoCard();
  }, [trigger]);
  const slideVideoCard = () => {
    if (!essentials.videoPlaying) {
      if (transition === "") {
        setTransition("t-100");
        setDotActiveness("second-dot-active");
      } else if (transition === "t-100") {
        setTransition("t-200");
        setDotActiveness("third-dot-active");
      } else {
        setDotActiveness("first-dot-active");
        setTransition("");
      }
    }
  };
  const sliderChange = (event) => {
    switch (event.target.id) {
      case "dot-1":
        setDotActiveness("first-dot-active");
        setTransition("");
        break;
      case "dot-2":
        setDotActiveness("second-dot-active");
        setTransition("t-100");
        break;
      case "dot-3":
        setDotActiveness("third-dot-active");
        setTransition("t-200");
        break;
      default:
        setTransition("");
        break;
    }
  };
  const onSwipe = (event) => {
    let swipeEndPosition = event.clientX || event.changedTouches[0].clientX;
    if (window.innerWidth <= 700) {
      if (swipeStart > swipeEndPosition) {
        switch (transition) {
          case "":
            setTransition("t-100");
            setDotActiveness("second-dot-active");
            break;
          case "t-100":
            setTransition("t-200");
            setDotActiveness("third-dot-active");
            break;
          default:
            setTransition(transition);
        }
      } else if (swipeStart < swipeEndPosition) {
        switch (transition) {
          case "t-200":
            setTransition("t-100");
            setDotActiveness("second-dot-active");
            break;
          case "t-100":
            setTransition("");
            setDotActiveness("first-dot-active");
            break;
          default:
            setTransition(transition);
        }
      }
    }
  };
  return (
    <div className="video-section">
      <h2 className="video-heading">Our video portfolio</h2>
      <p>
        Here is a small fraction of the videos we created over the past couple
        of years for our happy clients.
      </p>
      <div
        className={"video-slider " + transition}
        onTouchStart={(event) => {
          setSwipeStart(event.touches[0].clientX);
        }}
        onTouchEnd={onSwipe}
      >
        <div className="video-row-wrapper">
          <VideoCard
            videoId="videoOne"
            thumbnail="/images/10.png"
            video="https://firebasestorage.googleapis.com/v0/b/adways-2f160.appspot.com/o/videos%2F1000%20Below%20Rank%20On%20KCET.mp4?alt=media&token=08fea817-b0ce-4516-8fa3-8d87e063186b"
          />
          <VideoCard
            thumbnail="/images/9.png"
            video="https://firebasestorage.googleapis.com/v0/b/adways-2f160.appspot.com/o/videos%2FKCET%20Exam%20Ad_2.mp4?alt=media&token=7e07f693-9eca-40b0-b128-c2e17fa19418"
            videoId="videoTwo"
          />
          <VideoCard thumbnail="/images/001.png" videoId="videoThree" video="https://firebasestorage.googleapis.com/v0/b/adways-2f160.appspot.com/o/videos%2FTricks%20To%20Crack%20CET%20(1).mp4?alt=media&token=8cafdd00-8eda-450b-8a4f-e1f6cb869109" />
         
        </div>
        <div className="video-row-wrapper">
        <VideoCard
            thumbnail="/images/004.png"
            video="https://firebasestorage.googleapis.com/v0/b/adways-2f160.appspot.com/o/videos%2FRevamp24_Mega%20CET%20Webinar.mp4?alt=media&token=2be7d3c5-3f7f-4288-a9a0-c92fcf972725"
            videoId="videoFour"
          />
           <VideoCard
            thumbnail="/images/007.png"
            video="https://firebasestorage.googleapis.com/v0/b/adways-2f160.appspot.com/o/videos%2FRevamp24_KCET%20Webinar2.mp4?alt=media&token=af421f5e-ae47-4e05-90c9-21a84c2b6fcd"
            videoId="videoFive"
          />
           <VideoCard
            thumbnail="/images/005.png"
            video="https://firebasestorage.googleapis.com/v0/b/adways-2f160.appspot.com/o/videos%2FRevamp24_KCET%20Webinar.mp4?alt=media&token=6872682f-abd5-4881-a107-c25e492e2cbc"
            videoId="videoSix"
          />
        </div>
      </div>
      <div className="slider-dot">
        <div
          className={"dot " + dotActiveness}
          id="dot-1"
          onClick={sliderChange}
        ></div>
        <div
          className={"dot " + dotActiveness}
          id="dot-2"
          onClick={sliderChange}
        ></div>
        <div
          className={"dot " + dotActiveness}
          id="dot-3"
          onClick={sliderChange}
        ></div>
      </div>
    </div>
  );
}
