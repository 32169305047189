import React, { useEffect, useState } from "react";
import "./PageThree.scss";

export default function PageThree({setDescrip}) {
  const [description, setDescription] = useState("");

  const win = window.sessionStorage;
  const handleSubmit = (e) => {
    e.preventDefault();
    win.clear();
    setDescription(setDescrip);
  };

  useEffect(() => {
    if (win.getItem("description")) setDescription(win.getItem("description"));
  }, []);

  useEffect(() => {
    win.setItem("description", description);
  }, [description]);

  return (
    <div className="page-three">
      <form onSubmit={handleSubmit}>
        <h1>About your video</h1>
        <p>Please tell us a little bit about your video</p>
        <div className="textarea-wrap">
          <div>
            <label htmlFor="description">
              Please describe your video project.
            </label>
          </div>
          <textarea
            required
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            name="description"
            id="description"
          ></textarea>
        </div>
      </form>
    </div>
  );
}
