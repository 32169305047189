import React, { useState } from "react";
import "./Navbar.scss";
import { AiFillTwitterCircle } from "react-icons/ai";
import { FaFacebook, FaTelegram } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";

export default function Navbar() {
  const [navMobile, setNavMobile] = useState("");

  const handleNavLink = (id) => {
    document
      .getElementById(id)
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const navMobileHandle = () => {
    if (navMobile === "nav-active") {
      setNavMobile("");
    } else {
      setNavMobile("nav-active");
    }
  };
  const shareMobile = async () => {
    const shareData = {
      title: "Revamp24",
      text: "Get ready for KCET 2022 or 2023 with the most exhaustive and comprehensive online classroom program by the Leading Ed-Tech company in banglore.",
      url: "https://www.revamp24.in",
    };
    try {
      await navigator.share(shareData);
    } catch (err) {
      //no code
    }
  };
  return (
    <div className="navbar">
      <div className="for-mobile">
        <div className="burger" onClick={navMobileHandle}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <a href="/">
          {" "}
          <div className="logo-section">
            <img src="/images/Logo/Adways_logo.png" className="logo" alt="" />
            <div>adways</div>
          </div>
        </a>

        <img
          src="/images/Navbar/share.png"
          className="mobile-share"
          onClick={shareMobile}
          alt=""
        />
      </div>
      <ul className={navMobile}>
        {/* <li onClick={() =>{ handleNavLink("my-section");navMobileHandle()}}>Home</li> */}
        <li
          onClick={() => {
            handleNavLink("services");
            navMobileHandle();
          }}
        >
          Services
        </li>
        <li
          onClick={() => {
            handleNavLink("process");
            navMobileHandle();
          }}
        >
          How it Works
        </li>
        <li
          onClick={() => {
            handleNavLink("portfolio");
            navMobileHandle();
          }}
        >
          Portfolio
        </li>

        <li>
          <img src="/images/Navbar/share.png" className="share" alt="" />
          <ul className="share-dropdown">
            <li
              className="whatsapp-logo"
              onClick={() =>
                window.open(
                  "https://web.whatsapp.com/send?text=https://www.adways.in"
                )
              }
            >
              <IoLogoWhatsapp />
            </li>
            <li
              className="facebook-logo"
              onClick={() =>
                window.open(
                  "https://www.facebook.com/sharer.php?u=https://www.adways.in"
                )
              }
            >
              <FaFacebook />
            </li>
            <li
              className="twitter-logo"
              onClick={() =>
                window.open(
                  "https://twitter.com/intent/tweet?url=https://www.adways.in"
                )
              }
            >
              <AiFillTwitterCircle />
            </li>
            <li
              className="telegram-logo"
              onClick={() =>
                window.open(
                  "https://telegram.me/share/url?url=https://www.adways.in"
                )
              }
            >
              <FaTelegram />
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}
