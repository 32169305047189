import React from "react";
import "./PreparationCard.scss";
export default function preperationCard({
  cardTitle,
  cardImage,
  Title,
  Title2,
  Title3,
  prepareParagraph,
  prepareParagraph2,
  prepareParagraph3,
  curveImage,
  straightImage,
  downImage,
  titleCard,
}) {
  return (
    <div className="preparation_card_container">
      <h3 className={titleCard}>{cardTitle}</h3>
      <div className="preparation_card_main">
        <img className="main-image" src={cardImage} alt="" />
      </div>
      <div className="preparation_content">
        <h2>{Title}</h2>
        <p className="preparation_para">{prepareParagraph}</p>
      </div>
      <div className="preparation_content2">
        <h2>{Title2}</h2>
        <p className="preparation_para">{prepareParagraph2}</p>
      </div>
      <div className="preparation_content3">
        <h2>{Title3}</h2>
        <p className="preparation_para">{prepareParagraph3}</p>
      </div>
      <div className="curve-wrapper">
        <div className="curve">
          <div className="teach_image">
            <img src={curveImage} alt="" />
          </div>
        </div>
      </div>
      <div className="curve-straight-wrapper">
        <div className="curve_straight">
          <div className="teach_image1 ">
            <img src={straightImage} alt="" />
          </div>
        </div>
      </div>
      <div className="curve-down-wrapper">
        <div className="curve_down">
          <div className="teach_image1 ">
            <img src={downImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
