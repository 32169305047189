import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { EssentialProvider } from "./Assets/EssentialContext";
import MainRouter from "./Components/MainRouter";
import SubmitChoices from "./Components/Pages/PageWrapper/SubmitChoices";
import Calendly from "./Components/Pages/Calendly/Calendly";
import TermsConditions from "./Components/Pages/TermsandCondition/TermsConditions";
import PrivacyPolicy from "./Components/Pages/PrivacyandPolicy/PrivacyPolicy";
import ScrollToTop from "./Components/ScrollToTop";

export default function App() {
  return (
    <EssentialProvider>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<MainRouter />} />
          <Route path="submitchoices" element={<SubmitChoices />} />
          <Route path="calendly" element={<Calendly />} />
          <Route path="termsandconditions" element={<TermsConditions />} />
          <Route path="privacyandpolicy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </EssentialProvider>
  );
}
