import React from "react";
import PreparationCard from "./PreparationCard/PreparationCard";
import "./Preparation.scss";
export default function Preperation() {
  return (
    <div className="preparation_container" id="classroom-program">
      <h1>Advantages of choosing Adways</h1>
      <div className="preparation_main">
        <PreparationCard
          cardTitle=""
          cardImage="/images/image4.png"
          Title="Time-Efficiency"
          Title2="Professional-Grade Videos"
          Title3="Stress Reduction"
          prepareParagraph="For numerous busy business owners lacking the time or expertise to handle video editing themselves, outsourcing this task to our service enables them to divert their focus towards other critical business aspects. This not only enhances productivity but accelerates the achievement of business goals.
          "
          prepareParagraph2="A key factor in distinguishing your business from competitors and establishing a robust online presence is the quality of your videos. Our video editing service ensures that clients receive polished, visually appealing, and engaging content that resonates effectively with their target audience.
          "
          prepareParagraph3="Video editing can be both time-consuming and tedious. Entrusting this work to our professionals alleviates the stress and anxiety associated with editing tasks, allowing clients to redirect their time and energy towards more essential responsibilities.
          "
          curveImage="/images/time.png"
          straightImage="/images/editing.png"
          downImage="/images/stress.png"
          titleCard="card-title"
        />
        <PreparationCard
          cardTitle=""
          cardImage="/images/001_.png"
          Title="Improved Work-Life Balance"
          Title2="Facilitating Business Growth"
          Title3="Multicamera Editing"
          prepareParagraph="Balancing work and personal life is a common challenge for many business owners. Outsourcing video editing to our service provides them with more time to spend with loved ones and pursue personal interests and hobbies outside of work.
          "
          prepareParagraph2=" In an era where video marketing is crucial for reaching target audiences and fostering brand growth, our service ensures the creation of consistent, professional-looking content. This, in turn, attracts more customers and contributes to increased revenue for your business.
          "
          prepareParagraph3="Effortlessly synchronize and edit your multicamera interviews, transforming them into Hollywood-tier productions. Send us separate audio and video feeds, and we'll handle all post-production intricacies.
          "
          curveImage="/images/improve.png"
          straightImage="/images/growth.png"
          downImage="/images/multicam.png"
          titleCard="card-title2"
        />

        <h1>Our Areas of Expertise</h1>

        <PreparationCard
          cardTitle=""
          cardImage="/images/002_.png"
          Title="Color Correction and Grading"
          Title2="Crazy Cuts and Transitions"
          Title3="1080p, 4K, or Vertical"
          prepareParagraph="Fine-tune the colors in your videos to perfection.
           Utilize our expertise in adjusting color wheels, sharpness, shadows, highlights, and exposure for a visually stunning result.
           goals.
          "
          prepareParagraph2="Elevate your transitions with templates, plugins, or presets for a fast, dynamic, and mind-bending effect.
           Post-production relaxation involves creating transitions that stand out.
          
          "
          prepareParagraph3="Edit videos in any aspect ratio, be it the classic 1080p, the newer 4K resolution, or the trending vertical format for platforms like TikTok or YouTube shorts.
           Just let us know your preferences.
          
          "
          curveImage="/images/color grading.png"
          straightImage="/images/crazycut.png"
          downImage="/images/4k.png"
          titleCard="card-title"
        />

        <PreparationCard
          cardTitle=""
          cardImage="/images/003_.png"
          Title="Professional Editors"
          Title2="Stock Music and Footage"
          Title3="Motion Graphics"
          prepareParagraph="Collaborate with trained professionals, many of whom have backgrounds in filmmaking.
           Depending on your post-production needs, each client is assigned one or more dedicated video editors.
          
          "
          prepareParagraph2="Access licensed music and beautiful stock footage used as B-roll.
           Our licenses cover all assets used in your videos, eliminating the need for additional subscriptions.
          
          "
          prepareParagraph3="Enhance your videos with animated text callouts, pop-ups, titles, modern-looking lower thirds, and social channel callouts.
           These elements contribute to a fresh and clean visual appeal.
          
          "
          curveImage="/images/professional.png"
          straightImage="/images/music.png"
          downImage="/images/motion.png"
          titleCard="card-title2"
        />
      </div>

      <div id="services"></div>
    </div>
  );
}
