import React from "react";
import Footer from "../../footer/Footer";
import "./Calendly.scss";
import { InlineWidget } from "react-calendly";
import NavbarTwo from "../../Navbar/NavbarTwo";

export default function Calendly() {
  return (
    <>
      <NavbarTwo />
      <div className="calendly">
        <h1>Let's Have a Chat</h1>
        <p>
          Let’s discuss your video marketing plan that you can start using TODAY
          to become more visible online.
        </p>
        <div className="widget">
          <InlineWidget url="https://calendly.com/team-adways/vip-video-call-strategy-consultation" />
        </div>
      </div>
      <Footer />
    </>
  );
}
