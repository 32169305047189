import React from "react";
import "./PrivacyandPolicy.scss";

export default function PrivacyPolicy() {
  return (
    <div className="main-div">
      <h4>Privacy and policy</h4>
      <p>
        This privacy policy has been crafted to address concerns about the usage
        of Personally Identifiable Information (PII) online. PII, as defined in
        US privacy law, is information that can be used alone or in combination
        with other details to identify, contact, or locate an individual. Please
        carefully review our privacy policy to comprehend how we collect,
        utilize, protect, or handle your Personally Identifiable Information in
        line with our website.
      </p>
      <br />
      <p className="head">Collection of Personal Information:</p>
      <br />
      <p>
        When ordering or registering on our site, you may be prompted to provide
        your name, email address, mailing address, phone number, credit card
        information, or other details.
      </p>
      <br />
      <p className="head">Content:</p>
      <br />
      <p>
        By utilizing Veedyou's service, you are obligated to provide video
        footage and various content types such as graphics, photographs, audio,
        or project-related information. Your content is not shared, sold, or
        duplicated for external parties without your explicit consent.
      </p>
      <br />
      <p className="head">Information Collection Timing:</p>
      <br />
      <p>
        We collect information when you place an order, subscribe to a
        newsletter, or enter information on our site.
      </p>
      <br />
      <p className="head">Usage of Information:</p>
      <br />
      <p>
        The collected information is used for order processing, newsletter
        subscriptions, survey responses, and customer service-related
        communications.
      </p>
      <br />
      <p className="head">Information Protection:</p>
      <br />
      <p>
        We implement security measures, including Secure Socket Layer (SSL)
        technology, to protect your personal information. Transactions are
        processed through a secure gateway provider and are not stored or
        processed on our servers.
      </p>
      <br />
      <p className="head">Use of Cookies:</p>
      <br />
      <p>
        We do not use cookies for tracking purposes. You can manage cookie
        preferences through your browser settings.
      </p>
      <br />

      <p className="head">Third-party Disclosure:</p>
      <br />
      <p>
        We do not sell, trade, or transfer Personally Identifiable Information
        to external parties.
      </p>
      <br />
      <p className="head">Third-party Links:</p>
      <br />
      <p>
        Our website does not include or endorse third-party products or
        services.
      </p>
      <br />
      <p className="head">
        California Online Privacy Protection Act (CalOPPA):
      </p>
      <br />
      <p>
        We agree to allow users to visit our site anonymously. A link to our
        privacy policy is prominently placed on our homepage or the first
        significant page after entering the website. You will be notified of any
        Privacy Policy changes.
      </p>
      <br />
      <p className="head">COPPA (Children Online Privacy Protection Act):</p>
      <br />
      <p>
        We do not specifically market to children under the age of 13 years old.
      </p>
      <br />
      <p className="head">Fair Information Practices:</p>
      <br />
      <p>
        In the event of a data breach, we commit to notifying you within 7
        business days via email. We adhere to the Individual Redress Principle,
        ensuring individuals have enforceable rights against data collectors.
      </p>
      <br />
      <p className="head">CAN-SPAM Act:</p>
      <br />
      <p>
        We collect email addresses to send information, respond to inquiries,
        process orders, and provide updates. We comply with CAN-SPAM rules,
        including not using false subjects, identifying messages as
        advertisements, and promptly honoring opt-out requests.
      </p>
      <br />
      <p className="head">Contacting Us:</p>
      <p>Adways.in</p>
      <p>Bengaluru</p>
      <p>560102</p>
      <p>India</p>
    </div>
  );
}
