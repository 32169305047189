import { React, useEffect, useState } from "react";
import "./FormCard.scss";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { InlineWidget } from "react-calendly";

export default function FormCard() {
  const [formData, setFormData] = useState({});
  const [isSubmited, setIsSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const handleSubmit = (event) => {
    // window.open("https://calendly.com/team-adways/vip-video-call-strategy-consultation");
    event.preventDefault();
    appendSpreadsheet(formData);
    if (error) {
      alert("error");
      setError(false);
    } else {
      setIsSubmitted(true);
    }
  };
  const {
    REACT_APP_PRIVATE_KEY,
    REACT_APP_CLIENT_EMAIL,
    REACT_APP_SPREADSHEET_ID,
    REACT_APP_SHEET_ID,
  } = process.env;

  const doc = new GoogleSpreadsheet(REACT_APP_SPREADSHEET_ID);
  const appendSpreadsheet = async (row) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: REACT_APP_CLIENT_EMAIL,
        private_key: REACT_APP_PRIVATE_KEY,
      });
      await doc.loadInfo();
      console.log(doc.loadInfo());
      const sheet = doc.sheetsById[REACT_APP_SHEET_ID];
      const result = await sheet.addRow(row);
      return result;
    } catch (e) {
      setError(true);
    }
  };

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const [popup, setPopup] = useState(false);
  const closePopup = () => {
    setPopup(!popup);
  };
  return (
    <>
      <div className="form-main">
        {isSubmited ? (
          <>
            <div className="thankyou">
              <img src="/images/Landing/checked.png" alt="" />
              <h1>THANK YOU!</h1>
              <p>
                Your details have been submitted
                <br />
                successfully.
              </p>
            </div>
            {popup ? (
              ""
            ) : (
              <div className="popup">
                <h1>Let's Have a Chat</h1>
                <div onClick={closePopup} className="close">
                  ✖
                </div>
                <p>
                  Let’s discuss your video marketing plan that you can start
                  using TODAY to become more visible online.
                </p>
                <div className="widget-p">
                  <InlineWidget url="https://calendly.com/team-adways/vip-video-call-strategy-consultation" />
                </div>
              </div>
            )}
          </>
        ) : (
          <form onSubmit={handleSubmit}>
            <h5>Schedule a Free Strategy Video Call</h5>
            <p>
              Let’s discuss your video marketing plan that you can start using
              TODAY to become more visible online.
            </p>
            <input
              name="Name"
              className="inputs"
              placeholder="Name *"
              type="text"
              onChange={handleInputChange}
              required
            />
            <input
              name="Email"
              className="inputs"
              placeholder="Email *"
              type="email"
              onChange={handleInputChange}
              required
            />
            <input
              name="Mobile"
              className="inputs"
              type="text"
              placeholder="Phone Number *"
              onChange={handleInputChange}
              required
            />
            <input
              name="Company Name"
              className="inputs"
              type="text"
              placeholder="Company Name"
              onChange={handleInputChange}
            />
            <input
              name="Company Website"
              className="inputs"
              type="text"
              placeholder="Company Website"
              onChange={handleInputChange}
            />
            <button className="button" type="submit">
              Schedule a Video Call
            </button>
          </form>
        )}
      </div>
    </>
  );
}
