import { React, useState } from "react";
import FormCard from "../LandingPage/FormCard/FormCard";
import "./EnquireSection.scss";
import FrequentQuestions from "../FrequentQuestions/FrequentQuestions";

export default function EnquireSection() {
  const [buttonPopup, setButtonPopup] = useState(false);
  return (
    <div className="enquire-section">
      <div className="enquire-content-wrapper">
        <FrequentQuestions />
        <button
          onClick={() => setButtonPopup(!buttonPopup)}
          className="enquire-btn"
        >
          Enquire Now
        </button>
        <div className="pop-form">
          <div className="pop-form-wrapper">{buttonPopup && <FormCard />}</div>
        </div>
      </div>
    </div>
  );
}
