import React, { useEffect } from "react";
import "./Footer.scss";
import { FaFacebookF } from "react-icons/fa";
import {
  AiFillInstagram,
  AiFillLinkedin,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";


export default function Footer() {
  return (
    <div className="footer">
      <div className="footer_description">
        <h1>Company Info</h1>
        <p className="revamp_description">
          Adways, 3rd floor Ravnnas building, 27th Garden layout Rd, HSR Layout
          Bengaluru Karnataka 560102.
        </p>
      </div>
      <div className="footer_info">
        <h1 className="info_head">Legal Information</h1>
       
          <div className="copy-div">
          <ul className="footer_info_list">
          <li>
          <Link to='termsandconditions'><a href="">Terms and Conditions</a></Link>
            
          </li>
          <li>
         <Link to='privacyandpolicy'><a href="">Privacy and Policy</a></Link>
            
          </li>
          <p>Copyright 2024 Adways Media</p>
          <p>Built in India</p>
          <p>All rights reserved</p>
          </ul>
          </div>
         
        
      </div>
      <div className="footer_contact">
        <h1 className="contact_head">Contact us</h1>
        <ul className="footer_contact_list">
          <li>
            <img className="c-img" src="/images/Pasted image.png" alt="" />
            <p>
              <a
                href="tel:+91 7406457000
"
              >
                +91 7406457000
              </a>
            </p>
          </li>
          <li>
            <img className="c-img" src="/images/Pasted image 1.png" alt="" />
            <p>
              <a
                href="mailto:team.adways@gmail.com
"
              >
                team.adways@gmail.com
              </a>
            </p>
          </li>
          {/* <li>
                    <img src="/icons/pin.png" alt=""/>
                    
                </li> */}
        </ul>
        <ul className="footer_social_icons">
          <li>
            <a href="">
              <FaFacebookF className="social_icon" />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/adways.in/">
              <AiFillInstagram className="social_icon" />
            </a>
          </li>
          <li>
            <a href="">
              <AiFillLinkedin className="social_icon" />
            </a>
          </li>
          <li>
            <a href="">
              <AiFillYoutube className="social_icon" />
            </a>
          </li>
          <li>
            <a href="">
              <AiOutlineTwitter className="social_icon" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
