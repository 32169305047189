import React from "react";
import "./PageFour.scss";

export default function PageFour() {
  return (
    <div className="page-four">
      <h1>How do you want to get your quote ?</h1>
      <p>Thanks we have all the information we need.</p>
      <div className="button-wrap">
        <a href="https://api.whatsapp.com/send?phone=917406457000">
          <button>Chat with an agent</button>
        </a>

        <span style={{ fontSize: "12px" }}>or</span>
        <div>
          <p>Email me my quote</p>
          <span style={{ fontSize: "12px" }}>(click the next button)</span>
        </div>
      </div>
    </div>
  );
}
