import React from 'react'
import './MoreCard.scss'
export default function MoreCard({MoreImage,Morepara}) {
    return (
       <div className="morecard_container">
           <div className="morecard_main">
               <img src={MoreImage} alt=""/>
               <p>{Morepara}</p>
           </div>
       </div>
    )
}
