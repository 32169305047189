import React from "react";
import "./LearnDownloadCard.scss";

export default function LearnDownloadCard({ cardHead, img, para }) {
  return (
    <div className="learn-download-card">
      <h3>{cardHead}</h3>
      <img className="video-img" src={img} alt="" />
      <p className="process-para">{para}</p>
    </div>
  );
}
